import { createSelector } from '@reduxjs/toolkit';
const selectUi = (state) => state.ui;
export const selectCorrectionMode = createSelector([selectUi], (ui) => ui.correctionMode);
export const selectElectronCaptionMode = createSelector([selectUi], (ui) => ui.electronCaptionMode);
export const selectFullScreen = createSelector([selectUi], (ui) => ui.fullScreen);
export const selectDisplayShortcuts = createSelector([selectUi], (ui) => ui.displayShortcuts || ui.insertSpeakerNameMode);
export const selectInsertSpeakerNameMode = createSelector([selectUi], (ui) => ui.insertSpeakerNameMode);
export const selectIsInviteCardOpen = createSelector([selectUi], (ui) => ui.isInviteCardOpen);
export const selectIsSideBarOpen = createSelector([selectUi], (ui) => ui.sidebar);
export const selectLoading = createSelector([selectUi], (ui) => ui.loading !== undefined && ui.loading);
export const selectFontSize = createSelector([selectUi], (ui) => ui.fontSize);
export const selectIsIntercomOpen = createSelector([selectUi], (ui) => ui.intercomOpen);
export const selectElectronAppLoaded = createSelector([selectUi], (ui) => ui.electronAppLoaded !== undefined && ui.electronAppLoaded);
export const selectIsWordBeingEdited = createSelector([selectUi], (ui) => ui.wordBeingEdited);
export const selectKeyboardInputShown = createSelector([selectUi], (ui) => ui.keyboardInputShown);
export const selectIsConnectToMeetingOpen = createSelector([selectUi], (ui) => ui.isConnectToMeetingsOpen);
export const selectKaraokeState = createSelector([selectUi], (ui) => ui.karaokeState);
export const selectShouldHighlightConnectToMeetings = createSelector([selectUi], (ui) => ui.shouldHighlightConnectToMeetings);
export const selectOpenTauriAttempted = createSelector([selectUi], (ui) => ui.openTauriAttempted);
export const selectWasMutedBeforeKaraoke = createSelector([selectUi], (ui) => ui.wasMutedBeforeKaraoke);
export const selectIsOneClickScribeModalOpen = createSelector([selectUi], (ui) => ui.isOneClickScribeModalOpen);
