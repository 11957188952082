import { makeStyles } from '@mui/styles';
import { SnackbarContent } from 'notistack';
import React from 'react';
import { ConvoV2NotificationContent } from '../convoV2/ConvoV2NotificationContent';
const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
}));
const ConvoV2Notification = React.forwardRef(({ icon, message }, ref) => {
    const classes = useStyles();
    return (React.createElement(SnackbarContent, { className: classes.wrapper, ref: ref },
        React.createElement(ConvoV2NotificationContent, null,
            icon,
            message)));
});
export default ConvoV2Notification;
