import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { useEffect } from 'react';
import { logout } from '../../store/slices/auth';
import { useAppDispatch } from '../../store/store';
import { Z_INDICES } from '../../styles';
import animationData from './loader.json';
const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        display: 'flex',
        flexDirection: 'column',
        zIndex: Z_INDICES.loading,
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        background: theme.palette.ava.transparent.black[theme.palette.mode],
    },
    loader: {
        width: 150,
        height: 150,
    },
}));
export default function Loading({ className, children }) {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    useEffect(() => {
        const container = document.getElementById('lottie-animation');
        // react-lottie is a thing, but it weighs a lot, and we already load lottie in index.html
        // why we use animationData https://github.com/airbnb/lottie-web/issues/1925#issuecomment-1190650241
        if (container && window.lottie) {
            const animation = window.lottie.loadAnimation({
                container,
                renderer: 'svg',
                animationData,
                autoplay: true,
                loop: true,
            });
            return () => {
                animation.destroy();
            };
        }
    }, []);
    useEffect(() => {
        // on login users are supposed to be redirected and this loader renders while that happens
        // this is a failsafe to prevent an infinite loader in the case that the redirect fails or never issues for whatever reason
        const logoutTimeout = setTimeout(() => {
            logout(dispatch);
        }, 60000);
        return () => {
            clearTimeout(logoutTimeout);
        };
    }, []);
    return (React.createElement("div", { className: classnames(classes.root, className) },
        children,
        React.createElement("div", { className: classes.wrapper },
            React.createElement("div", { className: classes.loader, id: "lottie-animation" }))));
}
