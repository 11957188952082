import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-flex',
        gap: 16,
        padding: '12px 24px',
        alignItems: 'center',
        background: theme.palette.primary.main,
        color: theme.palette.ava.white,
        fontWeight: 400,
        fontSize: 16,
        borderRadius: 100,
    },
}));
export const ConvoV2NotificationContent = React.memo(({ children }) => {
    const classes = useStyles();
    return React.createElement(Box, { className: classes.root }, children);
});
